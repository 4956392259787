import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function popularMatchesReducer(
  state = initialState.popularMatches,
  action
) {
  switch (action.type) {
    case actionTypes.GET_POPULAR_MATCHES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
