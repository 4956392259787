import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class LeaderboardCard extends Component {
  render() {
    const { user, openModal } = this.props;

    return (
      <div className={"leaderboard_card " + (user.my ? "my" : "")}>
        <div className="left">
          <div className="queue">
            <p className="b_1">#{user.queue.toString().padEnd(4, " ")}</p>
          </div>
          <figure>
            <img src={user.img} alt="User Profile" />
          </figure>
          <p className="name b_1">{user.name}</p>
        </div>
        <div className="right">
          <p className="point b_1">
            <span>{user.points}</span>Puan
          </p>
          <div className="report">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
              </svg>
            </figure>
            <div className="content">
              <Link className="b_1">Profile Git</Link>
              <Link className="b_1" onClick={openModal}>
                Şikayet Et
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
