import { combineReducers } from "redux";

// MATCHES
import leagueListReducer from "./matches/leagueListReducer";
import matchesListReducer from "./matches/matchesListReducer";
import popularMatchesReducer from "./matches/popularMatchesReducer";
import teamsListReducer from "./matches/teamsListReducer";

// PREDICTIONS
import basketReducer from "./predictions/basketReducer";
import packagesReducer from "./predictions/packagesReducer";

// USERS
import registerorLoginReducer from "./users/registerorLoginReducer";
import getUserDetailsReducer from "./users/getUserDetailsReducer";
import userInactiveReducer from "./users/userInactiveReducer";
import canBeUsedReducer from "./users/canBeUsedReducer";
import verificationPhoneorMailReducer from "./users/verificationPhoneorMailReducer";
import verifyPhoneorMailReducer from "./users/verifyPhoneorMailReducer";
import sendFormObjectReducer from "./users/sendFormObjectReducer";
import resetPasswordCodeReducer from "./users/resetPasswordCodeReducer";

// STATICS
import getPhoneCodesReducer from "./statics/getPhoneCodesReducer";
import selectLeagueReducer from "./statics/selectLeagueReducer";
import selectTeamReducer from "./statics/selectTeamReducer";
import selectPhoneCodeReducer from "./statics/selectPhoneCodeReducer";

const rootReducer = combineReducers({
  // COUPONS
  basketReducer,
  packagesReducer,

  // MATCHES
  leagueListReducer,
  matchesListReducer,
  popularMatchesReducer,
  teamsListReducer,

  // USERS
  registerorLoginReducer,
  getUserDetailsReducer,
  userInactiveReducer,
  selectLeagueReducer,
  selectTeamReducer,
  selectPhoneCodeReducer,
  canBeUsedReducer,
  verificationPhoneorMailReducer,
  verifyPhoneorMailReducer,
  sendFormObjectReducer,
  resetPasswordCodeReducer,

  // STATICS
  getPhoneCodesReducer,
});

export default rootReducer;
