import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as userActions from "../../redux/actions/userActions";
import * as appJs from "../../js/app";
import MiniPreload from "../preload/MiniPreload";
import Match from "../components/matches/Match";

class Prediction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      btnLoad: false,
      btnDeleteLoad: false,
    };
  }

  componentDidMount() {
    this.tokenControl();
    if (appJs.tokenControl(this.props.token)) {
      this.props.actions.getBasket(this.props.token).then(() => {
        // console.log(this.props.basket);
      });
      this.props.actions.getUserDetails(this.props.token).then(() => {
        // console.log(this.props.userDetails);
      });
    }
  }

  tokenControl() {
    if (appJs.tokenControl(this.props.token)) {
      this.setState({
        isRedirect: false,
      });
    } else {
      this.setState({
        isRedirect: true,
      });
    }
  }

  deleteToBasket = (matchId) => {
    this.setState({ btnDeleteLoad: matchId });
    this.props.actions
      .deleteToBasket({ match_id: matchId }, this.props.token)
      .then(() => {
        this.setState({ btnDeleteLoad: false });
        window.location.reload();
      });
  };

  createPrediction = () => {
    this.setState({ btnLoad: true });
    this.props.actions.createPrediction(this.props.token).then((e) => {
      this.setState({ btnLoad: false });
      if (e.status === 200) {
        window.location.reload();
      }
    });
  };

  render() {
    if (this.state.isRedirect) return <Navigate to="/login" />;

    return this.props.basket.status === 200 ? (
      <div className="prediction_page">
        <h5 className="hood h_5">Tahmin Paketi</h5>
        {this.props.basket.result.basket.length <= 0 ? (
          <p className="hood h_8">
            Bu sayfada tahmin ettiğiniz maç sonuçlarını görebileceksiniz. Tahmin
            yaparak puan kazanabilir ve ödülleri kapabilirsiniz!
          </p>
        ) : (
          <></>
        )}
        <div
          className="prediction_page_content"
          style={
            this.props.basket.result.basket.length <= 0
              ? { alignItems: "center" }
              : {}
          }
        >
          <div
            className="right_to_and_points"
            style={
              this.props.basket.result.basket.length <= 0
                ? { justifyContent: "center" }
                : {}
            }
          >
            <div className="right_to_predictions">
              <div className="hood">
                <figure>
                  <img src="/img/package.png" alt="Prediction Img" />
                </figure>
                <h6 className="h_8">
                  Kalan Paket Hakkı
                  <span className="info">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                      </svg>
                    </figure>
                    <span className="modal b_4 non_select">
                      Her Salı Saat 05:00'da Yenilenir.
                    </span>
                  </span>
                </h6>
              </div>
              <p className="remaining_prediction h_8">
                {this.props.userDetails.coupons_right} / Hafta
              </p>
            </div>
            {this.props.basket.result.basket.length > 0 ? (
              <div className="total_points">
                <div className="hood">
                  <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="m22,11h-4.5c-1.103,0-2,.897-2,2v1.269c-.294-.171-.636-.269-1-.269h-5c-1.103,0-2,.897-2,2v1.269c-.294-.171-.636-.269-1-.269H2c-1.103,0-2,.897-2,2v5h24v-11c0-1.103-.897-2-2-2Zm-13.5,5c0-.551.448-1,1-1h5c.552,0,1,.449,1,1v7h-7v-7Zm-7.5,3c0-.551.448-1,1-1h4.5c.552,0,1,.449,1,1v4H1v-4Zm22,4h-6.5v-10c0-.551.448-1,1-1h4.5c.552,0,1,.449,1,1v10Zm-13.846-12.333l.623.474,2.223-1.441,2.205,1.441.641-.482-.707-2.364,1.861-1.5v-.795h-2.683l-.946-2.626h-.768l-.921,2.626h-2.683v.795l1.861,1.5-.707,2.372Zm.694-3.667h1.544l.608-1.735.608,1.735h1.544l-1.164.938.449,1.502-1.437-.932-1.438.932.449-1.502-1.164-.938ZM1.154,13.667l.623.474,2.223-1.441,2.205,1.441.641-.482-.707-2.364,1.861-1.5v-.795h-2.683l-.946-2.626h-.768l-.921,2.626H0v.795l1.861,1.5-.707,2.372Zm.694-3.667h1.544l.608-1.735.608,1.735h1.544l-1.164.938.449,1.502-1.437-.932-1.438.932.449-1.502-1.164-.938ZM21.317,3l-.946-2.626h-.768l-.921,2.626h-2.683v.795l1.861,1.5-.707,2.372.623.474,2.223-1.441,2.205,1.441.641-.482-.707-2.364,1.861-1.5v-.795h-2.683Zm-.329,1.938l.449,1.502-1.437-.932-1.438.932.449-1.502-1.164-.938h1.544l.608-1.735.608,1.735h1.544l-1.164.938Z" />
                    </svg>
                  </figure>
                  <h6 className="h_8">Kazanılacak Toplam Puan</h6>
                </div>
                <p className="remaining_prediction h_8">
                  {this.props.basket.result.total_points}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="predictions_and_settings">
            <div className="predictions">
              {this.props.basket.result.basket.length > 0 ? (
                this.props.basket.result.basket.map((prediction, i) => (
                  <Match
                    match={prediction}
                    basket={this.props.basket}
                    isPrediction={true}
                    deleteToBasket={this.props.actions.deleteToBasket}
                    token={this.props.token}
                    key={i}
                  />
                  // <div className="prediction" key={i}>
                  //   <div className="prediction_content">
                  //     <div className="teams">
                  //       <div className="team_names left">
                  //         <h6 className="team h_8">
                  //           {prediction.home_short_name}
                  //         </h6>
                  //         <span className="vs">VS</span>
                  //         <h6 className="team h_8 right">
                  //           {prediction.away_short_name}
                  //         </h6>
                  //       </div>
                  //       <div className="teams_last_matches">
                  //         <div className="team_last_matches left">
                  //           <p className="b_4">Son 5 Maç</p>
                  //           <div className="data">
                  //             {appJs
                  //               .formatLastMatch(
                  //                 prediction.home_last_5_matches !== null
                  //                   ? prediction.home_last_5_matches
                  //                   : "NNNNN"
                  //               )
                  //               .map((match, i) => (
                  //                 <span
                  //                   className={"match_info " + match}
                  //                   key={i}
                  //                 >
                  //                   <span className="modal non_select b_4">
                  //                     {match === "W"
                  //                       ? "Kazanan"
                  //                       : match === "L"
                  //                       ? "Kaybeden"
                  //                       : match === "D"
                  //                       ? "Berabere"
                  //                       : "Maç Yapılmamış"}
                  //                   </span>
                  //                 </span>
                  //               ))}
                  //           </div>
                  //         </div>
                  //         <div className="team_last_matches right">
                  //           <p className="b_4">Son 5 Maç</p>
                  //           <div className="data">
                  //             {appJs
                  //               .formatLastMatch(
                  //                 prediction.away_last_5_matches !== null
                  //                   ? prediction.away_last_5_matches
                  //                   : "NNNNN"
                  //               )
                  //               .map((match, i) => (
                  //                 <span
                  //                   className={"match_info " + match}
                  //                   key={i}
                  //                 >
                  //                   <span className="modal non_select b_4">
                  //                     {match === "W"
                  //                       ? "Kazanan"
                  //                       : match === "L"
                  //                       ? "Kaybeden"
                  //                       : match === "D"
                  //                       ? "Berabere"
                  //                       : "Maç Yapılmamış"}
                  //                   </span>
                  //                 </span>
                  //               ))}
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //     <h5 className="h_8">
                  //       {appJs.matchFormatDate(prediction.date)}
                  //     </h5>
                  //   </div>
                  //   <div className="selected_and_delete">
                  //     <div className="selected_and_point">
                  //       <div className="prediction_selected">
                  //         <p className="h_8">
                  //           Tahminin
                  //           <span className="h_7">
                  //             {appJs.formatMatchSelection(
                  //               prediction.bet_type,
                  //               prediction.home_score,
                  //               prediction.away_score
                  //             )}
                  //           </span>
                  //         </p>
                  //       </div>
                  //       <div className="prediction_point">
                  //         <p className="h_8">
                  //           Kazanılacak Puan
                  //           <span className="h_7">{prediction.points}</span>
                  //         </p>
                  //       </div>
                  //     </div>
                  //     <div className="delete">
                  //       <button
                  //         className="background_btn"
                  //         onClick={() =>
                  //           this.deleteToBasket(prediction.match_id)
                  //         }
                  //         disabled={
                  //           this.state.btnDeleteLoad === prediction.match_id
                  //             ? true
                  //             : false
                  //         }
                  //         data-loader={
                  //           this.state.btnDeleteLoad === prediction.match_id
                  //             ? true
                  //             : false
                  //         }
                  //       >
                  //         <span className="btn-loader s"></span>
                  //         <p className="b_4">Sil</p>
                  //       </button>
                  //     </div>
                  //   </div>
                  // </div>
                ))
              ) : (
                <div className="no_prediction">
                  <h6 className="h_7">Henüz Tahminde Bulunmadınız!</h6>
                  <Link to="/" className="b_3 background_btn">
                    Tahmin Yap!
                  </Link>
                </div>
              )}
            </div>
            {this.props.basket.result.basket.length > 0 ? (
              <button
                className="background_btn"
                onClick={this.createPrediction}
                disabled={this.state.btnLoad ? true : false}
                data-loader={this.state.btnLoad ? true : false}
              >
                <span className="btn-loader"></span>
                <p className="h_8">Tahminleri Paketle</p>
              </button>
            ) : (
              <></>
            )}
            {/* {this.props.basket.result.basket.length > 0 ? (
              <div className="settings">
                <span>
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z" />
                    </svg>
                  </figure>
                </span>
              </div>
            ) : (
              <></>
            )} */}
          </div>
        </div>
      </div>
    ) : (
      <MiniPreload />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
      deleteToBasket: bindActionCreators(
        predictionActions.deleteToBasket,
        dispatch
      ),
      getUserDetails: bindActionCreators(userActions.userDetails, dispatch),
      createPrediction: async (token) => {
        return await dispatch(predictionActions.createPrediction(token));
      },
    },
  };
}

function mapStateToProps(state) {
  return {
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
    userDetails: state.getUserDetailsReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Prediction);
