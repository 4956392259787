import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class LeaderboardKingCard extends Component {
  queueToTxt(queue) {
    if (queue === 1) {
      return "first";
    } else if (queue === 2) {
      return "second";
    } else if (queue === 3) {
      return "thirth";
    }
  }

  render() {
    const { user, openModal } = this.props;

    return (
      <div className={"leaderboard_king_card " + this.queueToTxt(user.queue)}>
        <div className="leaderboard_king_card_content">
          <div className="left">
            <figure className="queue">1</figure>
            <figure className="profile">
              <img src={user.img} alt="User Profile" />
            </figure>
          </div>
          <div className="right">
            <p className="h_8">{user.name}</p>
            <p className="h_8">
              <span>{user.points}</span>Puan
            </p>
          </div>
        </div>
        <div className="report">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
            </svg>
          </figure>
          <div className="content">
            <Link className="b_1">Profile Git</Link>
            <Link className="b_1" onClick={openModal}>
              Şikayet Et
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
