import React, { Component } from "react";
import * as appJs from "../../../js/app";
import ModalPrediction from "../../modals/ModalPrediction";

export default class Match extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedMatch: null,
      btnLoad: false,
    };
  }

  inBasket(match) {
    if (
      this.props.basket.result &&
      this.props.basket.result.basket &&
      this.props.basket.result.basket.some(
        (matchP) => matchP.match_id === match.id
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  openModal = (match) => {
    this.setState({
      isModalOpen: true,
      selectedMatch: match,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      selectedMatch: null,
    });
  };

  handleKeyDownCloseModal = (e) => {
    if (e.key === "Escape") {
      this.closeModal();
    }
  };

  deleteToBasket = (matchId) => {
    this.setState({ btnLoad: matchId });
    this.props
      .deleteToBasket({ match_id: matchId }, this.props.token)
      .then(() => {
        this.setState({ btnLoad: false });
        window.scrollTo(0, 0);
        window.location.reload();
      });
  };

  percentageCalc(total, number) {
    if (total !== 0) return ((100 * number) / total).toFixed(2);
    return 0;
  }

  render() {
    const { isModalOpen, selectedMatch } = this.state;
    const {
      match,
      isPrediction,
      isPackageDetail,
      basket,
      deleteToBasket,
      token,
    } = this.props;

    return (
      <>
        {!isPrediction && !isPackageDetail && isModalOpen && (
          <ModalPrediction
            match={selectedMatch}
            handleKey={this.handleKeyDownCloseModal}
            closeModal={this.closeModal}
          />
        )}
        {/* {!isPrediction && basket.result && ( */}
        <div
          className={
            "match_component " +
            (!isPrediction && !isPackageDetail && this.inBasket(match)
              ? "added"
              : "")
          }
          style={
            (isPrediction || isPackageDetail) && {
              width: "calc((100% / 2) - (36px / 2))",
            }
          }
        >
          {match.existing_bets > 0 && (
            <div className="existing_info">
              <figure>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                </svg>
              </figure>
              <span className="modal b_4 non_select">
                Bu Maça Daha Önce {match.existing_bets} Tahmin Yaptınız
              </span>
            </div>
          )}
          {!isPrediction && !isPackageDetail && this.inBasket(match)
            ? this.props.basket.result.basket.map((matchP) =>
                matchP.match_id === match.id ? (
                  <button
                    className="background_btn btn--delete_to_basket"
                    onClick={() => this.deleteToBasket(matchP.match_id)}
                    disabled={
                      this.state.btnLoad === matchP.match_id ? true : false
                    }
                    data-loader={
                      this.state.btnLoad === matchP.match_id ? true : false
                    }
                    key={matchP.match_id}
                  >
                    <span className="btn-loader s"></span>
                    {!this.state.btnLoad ? (
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                        </svg>
                      </figure>
                    ) : null}
                  </button>
                ) : null
              )
            : null}
          {/* {isPrediction&&()} */}
          <div className="match_content">
            <h5 className="h_8">{appJs.matchFormatDate(match.date)}</h5>
            <div className="teams">
              <div className="team_names left">
                <h6 className="team h_8">{match.home}</h6>
                <span className="vs">VS</span>
                <h6 className="team h_8 right">{match.away}</h6>
              </div>
              <div className="teams_last_matches">
                <div className="team_last_matches left">
                  <p className="b_4">Son 5 Maç</p>
                  <div className="data">
                    {appJs
                      .formatLastMatch(
                        match.home_last_5_matches !== null
                          ? match.home_last_5_matches
                          : "NNNNN"
                      )
                      .map((match, i) => (
                        <span className={"match_info " + match} key={i}>
                          <span className="modal non_select b_4">
                            {i + 1}-
                            {match === "W"
                              ? "Kazanan"
                              : match === "L"
                              ? "Kaybeden"
                              : match === "D"
                              ? "Berabere"
                              : "Maç Yapılmamış"}
                          </span>
                        </span>
                      ))}
                  </div>
                </div>
                <div className="team_last_matches right">
                  <p className="b_4">Son 5 Maç</p>
                  <div className="data">
                    {appJs
                      .formatLastMatch(
                        match.away_last_5_matches !== null
                          ? match.away_last_5_matches
                          : "NNNNN"
                      )
                      .map((match, i) => (
                        <span className={"match_info " + match} key={i}>
                          <span className="modal non_select b_4">
                            {i + 1}-
                            {match === "W"
                              ? "Kazanan"
                              : match === "L"
                              ? "Kaybeden"
                              : match === "D"
                              ? "Berabere"
                              : "Maç Yapılmamış"}
                          </span>
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {!isPrediction && !isPackageDetail && (
              <div className="statistics">
                <p className="b_2 non_select">
                  {match.total_bet_count === 0
                    ? "Hiç Tahmin Yapılmadı"
                    : `${match.total_bet_count} Tahmin Yapıldı`}
                </p>
                <div className="statistic">
                  <p className="b_3 non_select">
                    %
                    {this.percentageCalc(
                      match.total_bet_count,
                      match.home_bet_count
                    )}
                    <span>Ev</span>
                  </p>
                  <p className="b_3 non_select">
                    %
                    {this.percentageCalc(
                      match.total_bet_count,
                      match.draw_bet_count
                    )}
                    <span>Berabere</span>
                  </p>
                  <p className="b_3 non_select">
                    %
                    {this.percentageCalc(
                      match.total_bet_count,
                      match.away_bet_count
                    )}
                    <span>Deplasman</span>
                  </p>
                  <p className="b_3 non_select">
                    %
                    {this.percentageCalc(
                      match.total_bet_count,
                      match.score_bet_count
                    )}
                    <span>Skor</span>
                  </p>
                </div>
              </div>
            )}
          </div>
          {!isPrediction && !isPackageDetail && (
            <button
              className="background_btn"
              onClick={() => this.openModal(match)}
              disabled={this.inBasket(match)}
            >
              <span className="btn-loader"></span>
              <p className="b_3">
                {this.inBasket(match)
                  ? this.props.basket.result.basket.map((matchP) =>
                      matchP.match_id === match.id
                        ? appJs.formatMatchSelection(
                            matchP.bet_type,
                            matchP.home_score,
                            matchP.away_score
                          )
                        : null
                    )
                  : "Tahmin Yap"}
              </p>
            </button>
          )}

          {(isPrediction || isPackageDetail) && (
            <div className="selected_and_delete">
              <div className="selected_and_point">
                <div className="prediction_selected">
                  <p className="h_8">
                    Tahminin
                    <span className="h_7">
                      {appJs.formatMatchSelection(
                        match.bet_type,
                        match.home_score,
                        match.away_score
                      )}
                    </span>
                  </p>
                </div>
                <div className="prediction_point">
                  <p className="h_8">
                    Kazanılacak Puan
                    <span className="h_7">{match.points}</span>
                  </p>
                </div>
              </div>
              {!isPackageDetail && (
                <div className="delete">
                  <button
                    className="background_btn"
                    onClick={() => this.deleteToBasket(match.match_id)}
                    disabled={
                      this.state.btnLoad === match.match_id ? true : false
                    }
                    data-loader={
                      this.state.btnLoad === match.match_id ? true : false
                    }
                  >
                    <span className="btn-loader s"></span>
                    <p className="b_4">Sil</p>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {/* )} */}
      </>
    );
  }
}
