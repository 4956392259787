import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import Leaderboard from "../pages/Leaderboard";
import Matches from "../pages/Matches";
import NotFound from "../pages/NotFound";
import Homepage2 from "../iyzico/Homepage2";
import Homepage from "../pages/Homepage";
import RegisterandLogin from "../pages/RegisterandLogin";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer";
import ConditionsOfUse from "../declarations/ConditionsOfUse";
import PrivacyPolicy from "../declarations/PrivacyPolicy";
import FAQs from "../declarations/FAQs";
import FooterMini from "../layouts/FooterMini";
import Prediction from "../pages/Prediction";
import BugReport from "../layouts/BugReport";
import Leagues from "../pages/Leagues";
import PackageDetail from "../pages/PackageDetail";
import Packages from "../pages/Packages";
import * as AppJs from "../../js/app";

function Dashboard(props) {
  return (
    <>
      <Navigation />
      <ScrollToTop />
      <Routes>
        {AppJs.tokenControl(props.token) ? (
          <Route path="/" element={<Homepage />} />
        ) : (
          <Route path="/" element={<Homepage2 />} />
        )}

        <Route path="leaderboard" element={<Leaderboard />} />
        <Route path="leagues" element={<Leagues />} />
        <Route path="matches" element={<Matches />} />
        <Route path="package_detail" element={<PackageDetail />} />
        <Route path="packages" element={<Packages />} />
        <Route path="prediction" element={<Prediction />} />
        <Route path="register/*" element={<RegisterandLogin />} />
        <Route path="login/*" element={<RegisterandLogin />} />
        <Route path="conditions_of_use" element={<ConditionsOfUse />} />
        <Route path="privacy_policy" element={<PrivacyPolicy />} />
        <Route path="FAQs" element={<FAQs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {AppJs.tokenControl(props.token) ? (
        <>
          <BugReport />
          <FooterMini />
        </>
      ) : (
        <Footer />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps)(Dashboard);
