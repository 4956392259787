// STATICS
export const GET_PHONE_CODES = "GET_PHONE_CODES";
export const GET_PHONE_CODES_SUCCESS = "GET_PHONE_CODES_SUCCESS";
//
export const SELECT_PHONE_CODE = "SELECT_PHONE_CODE";
//
export const ADD_TO_PREDICTION = "ADD_TO_PREDICTION";

// PREDICTIONS
export const GET_BASKET = "GET_BASKET";
export const GET_BASKET_SUCCESS = "GET_BASKET_SUCCESS";
export const PACKAGES_LIST = "PACKAGES_LIST";
export const PACKAGES_LIST_SUCCESS = "PACKAGES_LIST_SUCCESS";
export const DELETE_TO_BASKET = "DELETE_TO_BASKET";
export const DELETE_TO_BASKET_SUCCESS = "DELETE_TO_BASKET_SUCCESS";
export const ADD_TO_BASKET = "ADD_TO_BASKET";

// MATCHES
export const MATCH_DETAILS = "MATCH_DETAILS";
export const MATCH_DETAILS_SUCCESS = "MATCH_DETAILS_SUCCESS";
export const GET_LEAGUE_LIST = "GET_LEAGUE_LIST";
export const GET_LEAGUE_LIST_SUCCESS = "GET_LEAGUE_LIST_SUCCESS";
export const GET_LEAGUE_TEAMS = "GET_LEAGUE_TEAMS";
export const GET_LEAGUE_TEAMS_SUCCESS = "GET_LEAGUE_TEAMS_SUCCESS";
export const GET_POPULAR_MATCHES = "GET_POPULAR_MATCHES";
export const GET_POPULAR_MATCHES_SUCCESS = "GET_POPULAR_MATCHES_SUCCESS";
export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_UPCOMING_MATCHES = "GET_UPCOMING_MATCHES";
export const GET_UPCOMING_MATCHES_SUCCESS = "GET_UPCOMING_MATCHES_SUCCESS";
export const GET_WEEKLY_MATCHES = "GET_WEEKLY_MATCHES";
export const GET_WEEKLY_MATCHES_SUCCESS = "GET_WEEKLY_MATCHES_SUCCESS";

// USERS
export const USER_INACTIVE = "USER_INACTIVE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_REMEMBER = "LOGIN_SUCCESS_REMEMBER";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const RESET_PASSWORD_CODE_SUCCESS = "RESET_PASSWORD_CODE_SUCCESS";
export const RESET_PASSWORD_CODE = "RESET_PASSWORD_CODE";
export const RESET_PASSWORD_CODE_VERIFY_SUCCESS =
  "RESET_PASSWORD_CODE_VERIFY_SUCCESS";
export const RESET_PASSWORD_CODE_VERIFY = "RESET_PASSWORD_CODE_VERIFY";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_CODE = "VERIFY_EMAIL_CODE";
export const VERIFY_EMAIL_CODE_SUCCESS = "VERIFY_EMAIL_CODE_SUCCESS";
export const VERIFY_PHONE = "VERIFY_PHONE";
export const VERIFY_PHONE_SUCCESS = "VERIFY_PHONE_SUCCESS";
export const VERIFY_PHONE_CODE = "VERIFY_PHONE_CODE";
export const VERIFY_PHONE_CODE_SUCCESS = "VERIFY_PHONE_CODE_SUCCESS";

//
export const SELECT_LEAGUE = "SELECT_LEAGUE";
export const SELECT_TEAM = "SELECT_TEAM";

//
export const SEND_FORM_OBJECT = "SEND_FORM_OBJECT";

//
export const PHONE_CONTROL = "PHONE_CONTROL";
export const EMAIL_CONTROL = "EMAIL_CONTROL";
export const USERNAME_CONTROL = "USERNAME_CONTROL";
