import alertify from "alertifyjs";
import React, { Component } from "react";

export default class ReportUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        user_id: this.props.user.id,
        report_type: null,
        text: "",
      },
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.props.handleKey);
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.props.handleKey);
    document.body.style.overflow = "auto";
    this.setState({ form: { user_id: null, report_type: null, text: "" } });
  }

  handleRadioButton = (e) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        report_type: e.target.value,
      },
    }));
  };

  handleChange = (e) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        text: e.target.value,
      },
    }));
  };

  send = (e) => {
    e.preventDefault();
    if (
      this.state.form.user_id !== null &&
      this.state.form.user_id !== undefined &&
      this.state.form.report_type !== null &&
      this.state.form.report_type !== "" &&
      this.state.form.text !== ""
    ) {
      console.log("Giris Basarili");
    } else {
      if (
        this.state.form.user_id === null ||
        this.state.form.user_id === undefined
      ) {
        alertify.error("HATA: Lütfen daha sonra tekrar deneyin!", 5);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        alertify.error("Tüm alanları doldurmanız gerekiyor!", 5);
      }
    }
  };

  render() {
    const { closeModal, user } = this.props;

    return (
      <div className="modal_report_user ">
        <div className="popup_report_user hide-scroll">
          <div className="top">
            <div className="user_details">
              <figure>
                <img src={user.img} alt="User Profile" />
              </figure>
              <p className="h_7">{user.name}</p>
            </div>
            <div className="report">
              <h6 className="h_6">Oyuncuyu Şikayet Et</h6>
              <div className="report_type">
                <div className="type_username">
                  <input
                    type="radio"
                    name="report_type"
                    id="username"
                    value={1}
                    onChange={this.handleRadioButton}
                    autoComplete="false"
                  />
                  <label htmlFor="username" className="b_1">
                    Argo, ırkçı ve ofansif kelimeler içeren isim<span>*</span>
                  </label>
                </div>
                <div className="type_picture">
                  <input
                    type="radio"
                    name="report_type"
                    id="picture"
                    value={2}
                    onChange={this.handleRadioButton}
                    autoComplete="false"
                  />
                  <label htmlFor="picture" className="b_1">
                    Uygunsuz profil fotoğrafı<span>*</span>
                  </label>
                </div>
                <div className="type_other">
                  <input
                    type="radio"
                    name="report_type"
                    id="other"
                    value={3}
                    onChange={this.handleRadioButton}
                    autoComplete="false"
                  />
                  <label htmlFor="other" className="b_1">
                    Diğer<span>*</span>
                  </label>
                </div>
              </div>
              <div className="desc">
                <label htmlFor="desc" className="b_1">
                  Açıklama<span>*</span>
                </label>
                <textarea
                  className="b_3 input hide-scroll"
                  name="desc"
                  id="desc"
                  maxLength={255}
                  value={this.state.form.text}
                  onChange={this.handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="buttons">
            <button className="outline_btn h_8" onClick={closeModal}>
              İptal
            </button>
            <button className="background_btn h_8" onClick={this.send}>
              Şikayet Et
            </button>
          </div>
        </div>
      </div>
    );
  }
}
