import React, { Component } from "react";

export default class ProgressBar extends Component {
  updatePercent = (point, percent) => {
    return `${(100 * point) / percent}%`;
  };

  render() {
    const { point, percent } = this.props;
    return (
      <div className="progress_bar non_select--img">
        <span
          className="progress"
          style={{
            width: this.updatePercent(point, percent),
          }}
        ></span>
      </div>
    );
  }
}
