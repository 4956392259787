import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PackageItem from "../components/packages/PackageItem";
import WeekDropdown from "../components/packages/WeekDropdown";
import StatusDropdown from "../components/packages/StatusDropdown";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as userActions from "../../redux/actions/userActions";
import MiniPreload from "../preload/MiniPreload";

class Packages extends Component {
  filterWeek = ["Tüm Haftalar"];
  filterStatus = [
    "Aktif Paketlerim",
    "Tamamlanmış Paketlerim",
    "Tüm Paketlerim",
  ];
  splitFilter = window.location.search.match(/status=([^&]*)/) || [1, 1];
  splitWeek = window.location.search.match(/week=([^&]*)/) || [0, 0];

  componentDidMount() {
    if (this.splitFilter[1] != 1) this.getWeek();

    this.props.actions.getPackages(
      this.props.token,
      this.splitFilter[1],
      this.splitWeek[1]
    );
  }

  getWeek = async () => {
    this.props.actions.getCounters(this.props.token, "week").then((week) => {
      for (let i = 0; i < week.counter; i++) {
        this.filterWeek[i + 1] =
          i + 1 !== week.counter ? i + 1 + ". Hafta" : "Bu Hafta";
      }
    });
  };

  render() {
    return (
      <div className="packages_page">
        <div className="packages_hood">
          <h5 className="h_5">Paketlerim</h5>
          <p className="h_8">
            Bu sayfada tahmin ettiğiniz maç sonuçlarını görebileceksiniz. Tahmin
            yaparak puan kazanabilir ve ödülleri kapabilirsiniz!
          </p>
        </div>
        <div className="packages_filter">
          <div className="filter">
            <StatusDropdown
              status={this.filterStatus}
              splitFilter={this.splitFilter}
            />
            {this.splitFilter[1] != 1 &&
              (this.filterWeek?.length > 1 ? (
                <WeekDropdown
                  week={this.filterWeek}
                  splitWeek={this.splitWeek}
                />
              ) : (
                <MiniPreload />
              ))}
          </div>
          <div className="order"></div>
        </div>
        <div className="package_items">
          {this.props.packages && this.props.packages.status === 200 ? (
            this.props.packages.result.coupon_data.map((c) => (
              <PackageItem packages={c} key={c.id} />
            ))
          ) : (
            <MiniPreload />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
    packages: state.packagesReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getPackages: bindActionCreators(predictionActions.packagesList, dispatch),
      getCounters: bindActionCreators(userActions.counters, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
