import React, { Component } from "react";
import LeaderboardCard from "../components/leaderboard/LeaderboardCard";
import LeaderboardKingCard from "../components/leaderboard/LeaderboardKingCard";
import WeekDropdown from "../components/leaderboard/WeekDropdown";
import ReportUser from "../components/ReportUser";

export default class Leaderboard extends Component {
  filter = ["Birinci Lig", "Süper Lig", "Avrupa Ligi", "Şampiyonlar Ligi"];
  week = ["1. Hafta", "2. Hafta"];
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleKeyDownCloseModal = (e) => {
    if (e.key === "Escape") {
      this.closeModal();
    }
  };

  render() {
    return (
      <div className="leaderboard_page">
        {this.state.isModalOpen && (
          <ReportUser
            user={{ id: 1, img: "/img/1m1.svg", name: "enes" }}
            handleKey={this.handleKeyDownCloseModal}
            closeModal={this.closeModal}
          />
        )}
        <h5 className="h_5 hood">Liderlik Tablosu</h5>
        <div className="info_leaderboard">
          <div className="total_player total">
            <figure></figure>
            <div className="content_total">
              <p className="b_1">Toplam Oyuncu Sayısı</p>
              <h6 className="h_7">10.000 +</h6>
            </div>
          </div>
          <div className="total_points total">
            <figure></figure>
            <div className="content_total">
              <p className="b_1">Toplam Puanın</p>
              <h6 className="h_7">789.321 Puan</h6>
            </div>
          </div>
          <div className="remaining_time">
            <div className="remaining_time_content">
              <h6 className="h_6">SEZONUN BİTMESİNE KALAN SÜRE</h6>
              <p className="h_6">
                <span className="day">
                  <span>283</span>
                  <span className="h_8">Gün</span>
                </span>
                :
                <span className="hours">
                  <span>12</span>
                  <span className="h_8">Saat</span>
                </span>
              </p>
            </div>
            <p className="b_1">
              Her üyelik liginin , kendi içersindeki birinciler toplamda 4
              farklı birinci olmak üzere süpriz ödülleri kazanacak
            </p>
          </div>
        </div>
        <div className="leaderboard_sect">
          <p className="h_7 hood">
            <span>
              {
                this.filter[
                  window.location.search
                    ? window.location.search.split("=")[1]
                    : 0
                ]
              }
            </span>
            Sıralaması
          </p>
          <div className="filter">
            <WeekDropdown week={this.week} />
          </div>
          <div className="leaderboard">
            <LeaderboardKingCard
              openModal={this.openModal}
              user={{
                queue: 1,
                name: "Enes",
                points: 888,
                img: "/img/1m1.svg",
              }}
            />
            <LeaderboardKingCard
              openModal={this.openModal}
              user={{
                queue: 2,
                name: "Enes",
                points: 888,
                img: "/img/1m1.svg",
              }}
            />
            <LeaderboardKingCard
              openModal={this.openModal}
              user={{
                queue: 3,
                name: "Enes",
                points: 888,
                img: "/img/1m1.svg",
              }}
            />
            <LeaderboardCard
              openModal={this.openModal}
              user={{
                queue: 1,
                name: "Enes",
                points: 888,
                img: "/img/1m1.svg",
              }}
            />
            <LeaderboardCard
              openModal={this.openModal}
              user={{
                queue: 2,
                name: "Ahmet",
                points: 888,
                img: "/img/1m1.svg",
              }}
            />
            <LeaderboardCard
              openModal={this.openModal}
              user={{
                queue: 3,
                name: "Mehmet",
                points: 888,
                img: "/img/1m1.svg",
                my: false,
              }}
            />
            <div className="user_queue">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <LeaderboardCard
                openModal={this.openModal}
                user={{
                  queue: 3456,
                  name: "asdasfd",
                  points: 888,
                  img: "/img/1m1.svg",
                  my: true,
                }}
              />
            </div>
          </div>
          <button className="outline_btn">
            <span className="btn-loader s"></span>
            <p className="h_8">Tüm Sıralamayı Görüntüle</p>
          </button>
        </div>
      </div>
    );
  }
}
