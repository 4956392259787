import React, { Component } from "react";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import alertify from "alertifyjs";

class BugReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   isRedirect: false,
      btnLoad: false,
      //   isPhone: true,
      form: {
        subject: "",
        message: "",
        document: "",
      },
    };
  }

  handleFileChange = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.files[0],
        },
      }),
      () => {}
    );
  };

  handleChange = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.value,
        },
      }),
      () => {}
    );
  };

  send = (e) => {
    e.preventDefault();
    this.setState({ btnLoad: true });
    if (this.state.form.message !== "" && this.state.form.subject !== "") {
      const imgData = new FormData();
      imgData.append("screenshot", this.state.form.document);

      this.props.actions
        .createBugReport(
          imgData,
          this.state.form.message,
          this.state.form.subject,
          this.props.token
        )
        .then((response) => {
          this.setState({ btnLoad: false });

          if (response.status === 200) {
            alertify.success("Mesajiniz Gonderildi", 10);
          }
        });
    } else {
      this.setState({ btnLoad: false });
      alertify.error("Gerekli Alanları Doldurun!", 5);
    }
  };

  render() {
    return (
      <section className="bug_report_sect">
        <div className="bug_report_page">
          <h6 className="h_6">Hata Bildirimi</h6>
          <form onSubmit={this.send}>
            <div className="inputs">
              <div className="subject_image">
                <div className="subject">
                  <label className="b_2" htmlFor="subject">
                    Konu
                    <span>*</span>
                  </label>
                  <select
                    className="input b_2"
                    id="subject"
                    name="subject"
                    value={this.state.form.subject}
                    onChange={this.handleChange}
                  >
                    <option className="b_2" value="" disabled defaultValue>
                      Konuyu Seçiniz...
                    </option>
                    <option className="b_2" value="1">
                      Kupon
                    </option>
                    <option className="b_2" value="2">
                      Maç
                    </option>
                    <option className="b_2" value="3">
                      Kullanıcı
                    </option>
                    <option className="b_2" value="4">
                      Başarı Ödülleri
                    </option>
                    <option className="b_2" value="5">
                      Liderlik Tablosu
                    </option>
                    <option className="b_2" value="6">
                      Puanlar
                    </option>
                  </select>
                </div>
                <div className="image">
                  <label className="b_2" htmlFor="document">
                    Hata Görseli
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    id="document"
                    className="input b_2"
                    name="document"
                    onChange={this.handleFileChange}
                  />
                </div>
              </div>
              <div className="message">
                <label className="b_2" htmlFor="message">
                  Mesajınız
                  <span>*</span>
                </label>
                <textarea
                  name="message"
                  id="message"
                  className="input b_2 hide-scroll"
                  value={this.state.form.message}
                  onChange={this.handleChange}
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              className="background_btn"
              disabled={this.state.btnLoad ? true : false}
              data-loader={this.state.btnLoad ? true : false}
            >
              <span className="btn-loader"></span>
              <p className="b_1">GÖNDER</p>
            </button>
          </form>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createBugReport: async (
        img,
        details,
        bug_type,
        // screenshot_added,
        token
      ) => {
        return await dispatch(
          userActions.createBugReport(
            img,
            details,
            bug_type,
            // screenshot_added,
            token
          )
        );
      },
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BugReport);
