import React, { Component } from "react";

export default class WeekDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedWeek: { name: this.props.week[0], i: 0 },
    };
  }

  componentDidMount() {
    if (window.location.search) {
      this.setState({
        selectedWeek: {
          name: this.props.week[this.props.splitWeek[1]],
          i: this.props.splitWeek[1],
        },
      });
    }
  }

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option, i) => {
    this.setState(
      {
        isOpen: false,
        selectedWeek: { name: option, i },
      },
      () => {
        const href = "week=" + this.state.selectedWeek.i;

        if (window.location.search.includes("week=")) {
          let newSearch;
          if (this.state.selectedWeek.i === 0) {
            newSearch = window.location.search.replace(/&week=[^&]*/, "");
          } else {
            newSearch = window.location.search.replace(/week=[^&]*/, href);
          }
          window.location.search = newSearch;
        } else {
          if (this.state.selectedWeek.i !== 0)
            window.location.search += `&${href}`;
        }
      }
    );
  };

  render() {
    const { week } = this.props;

    return (
      <div className="week">
        <p className="b_1 non_select">Hafta</p>
        <div className="packages-dropdown">
          <div
            className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
            onClick={(e) => this.toggleDropdown(e)}
          >
            <div className="content-drop">
              <div className="txt">
                <p className="b_3 non_select">
                  {this.state.selectedWeek !== null
                    ? this.state.selectedWeek.name
                    : null}
                </p>
              </div>
              <figure className={`arrow`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" />
                </svg>
              </figure>
            </div>
          </div>
          {this.state.isOpen && (
            <ul className="options hide-scroll">
              {week.map((w, i) => (
                <li
                  className="option"
                  key={i}
                  onClick={() => this.handleOptionClick(w, i)}
                >
                  <div className="txt">
                    <p className="b_3 non_select">{w}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}
