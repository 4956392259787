import React, { Component } from "react";
import { Link } from "react-router-dom";
import Leagues from "../leagues/Leagues";
import MainAdsorImg from "../components/MainAdsorImg";
import { connect } from "react-redux";
import Achievements from "../components/achievements/Achievements";
import FavoriteMatch from "../Match/FavoriteMatch";
// import * as userActions from "../../redux/actions/userActions";

class Homepage extends Component {
  render() {
    return (
      <div className="homepage">
        <MainAdsorImg />
        {/* <EmailInput /> */}
        <div className="homepage_buttons">
          <Link to="coming-soon">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M512 416c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l128 0c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8l160 0c35.3 0 64 28.7 64 64l0 256zM232 376c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64 64 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-64 0 0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 64-64 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l64 0 0 64z" />
              </svg>
            </figure>
            <p className="h_8">Liderlik Tablosu</p>
          </Link>
          <Link to="packages">
            <figure>
              <img src="/img/package.png" alt="Package" />
            </figure>
            <p className="h_8">Paketlerim</p>
          </Link>
        </div>
        <Leagues />
        <FavoriteMatch />
        {this.props.userDetails ? (
          !this.props.userDetails.is_premium ? (
            <div className="premium_discover">
              <figure>
                <img src="/img/premium.png" alt="Premium" />
              </figure>
              <div className="premium_discover_content">
                <h6 className="h_7">
                  <span className="h_5">Premium'a</span>
                  Geç ve Avrupa Liglerinde Oyna!
                </h6>
                <p className="b_1">
                  Premium üyelikle Şampiyonlar Ligi ve Avrupa Ligine katıl, daha
                  büyük ödüller kazan!
                  <span className="b_3">
                    (örn. özel ligler, büyük ödüller, ekstra puan)
                  </span>
                </p>
              </div>
              <Link to="coming-soon" className="background_btn h_8">
                Premium’u Keşfet
              </Link>
            </div>
          ) : null
        ) : null}

        {/* <Achievements /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.getUserDetailsReducer,
  };
}

export default connect(mapStateToProps, null)(Homepage);
