import React, { Component } from "react";
import { Link } from "react-router-dom";
import MiniPreload from "../preload/MiniPreload";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as matchActions from "../../redux/actions/matchActions";
import League from "../components/leagues/League";

class Leagues extends Component {
  componentDidMount() {
    if (this.props.leagues.length <= 0) {
      this.props.actions.getLeagues();
    }
  }

  render() {
    return (
      <div className="leagues_page">
        <div className="hood_league">
          <h5 className="h_5">Ligler</h5>
        </div>
        <div className="leagues">
          {this.props.leagues.length <= 0 ? (
            <MiniPreload />
          ) : (
            this.props.leagues.map((league) => (
              <League
                league={league}
                is_premium={this.props.userDetails.is_premium}
                key={league.id}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getLeagues: bindActionCreators(matchActions.getLeagueList, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    leagues: state.leagueListReducer,
    userDetails: state.getUserDetailsReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Leagues);
