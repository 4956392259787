import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";

// Register
import RegisterForm from "../register/Form";
import FavTeamAndLeague from "../register/FavTeamAndLeague";
import VerifyCode from "../register/VerifyCode";

// Login
import LoginForm from "../login/Form";
import ForgotPassword from "../login/ForgotPassword";

// IMG
// import imgFottball from "../../img/register.png";
import imgFottball from "../../img/register.png";

import PageScrollToTop from "../scrollToTop/PageScrollToTop";
import NotFound from "./NotFound";
import * as Appjs from "../../js/app";
import Navigation from "../layouts/Navigation";

class RegisterandLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
    };
  }

  componentDidMount() {
    Appjs.registerOverflow();
    this.tokenControl();
    document.getElementById("root").style.padding = 0;
  }

  tokenControl() {
    if (Appjs.tokenControl(this.props.token)) {
      this.setState({ isRedirect: true });
    } else {
      this.setState({ isRedirect: false });
    }
  }

  render() {
    if (this.state.isRedirect) {
      return <Navigate to="/" />;
    }

    return (
      <div className="register-login-page">
        <div className="img">
          <img src={imgFottball} alt="Fottball" />
        </div>
        {window.location.pathname.startsWith("/register") ? (
          <div className="content register" id="overflow-register">
            <Navigate to="/login" />
            {/* <h6 className="h_6">Kayıt Ol</h6>
            <PageScrollToTop targetId={"overflow-register"} />
            <Routes>
              <Route path="/" element={<RegisterForm />} />
              <Route path="verify_code" element={<VerifyCode />} />
              <Route
                path="favorite_league_and_team"
                element={<FavTeamAndLeague />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes> */}
          </div>
        ) : (
          <div className="content login" id="overflow-register">
            <h4 className="h_6 main-hood">Giriş Yap</h4>
            <PageScrollToTop targetId={"overflow-register"} />
            <Routes>
              <Route path="/" element={<LoginForm />} />
              <Route path="forgot_password/*" element={<ForgotPassword />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps)(RegisterandLogin);
